import React from 'react'
import AboutHeader from '../../components/about_header/about_header'
import Stats from '../../components/stats/stats'
import CommunitySection from '../../components/community_section'
import CompanyProductSection from '../../components/company_product_section'
import TestimonialsSection from '../../components/testimonials_section'
import RaggedEdge from '../../components/ragged_edge/ragged_edge'
import TimelineSection from '../../components/timeline_section'
import Locations from '../../components/locations'
import { frontdoorPaths } from '../../routing/paths'
import { Analytics } from '@envato/google-analytics'

export default () => (
  <>
    <AboutHeader />
    <Stats />
    <RaggedEdge />
    <Analytics.Section trackingId='our_community'>
      <CommunitySection />
    </Analytics.Section>
    <Analytics.Section trackingId='our_products'>
      <CompanyProductSection />
    </Analytics.Section>
    <TestimonialsSection />

    <Analytics.Section trackingId='timeline'>
      <TimelineSection />
    </Analytics.Section>

    <Locations
      titleText='Come and work with us'
      supportingText='More than 600 people around the world support the work
      of thousands of digital creatives, all united behind a set of core values
      that put our creative community first. And as a certified B Corp,
      you will join a company where your work will have a lasting, positive impact.'
      linkHref={frontdoorPaths.aboutCareers}
      linkLabel='Visit Careers'
    />
  </>
)
