import React from 'react'
import PropTypes from 'prop-types'
import styles from './layout.module.css'
import CenteredSectionWrapper from '../../centered_section_wrapper/centered_section_wrapper'

export default function FigmaLayout({ type, children }) {
  return (
    <CenteredSectionWrapper>
      <div className={styles[type]}>
        {React.Children.map(children, (child) => (
          <div className={styles.childWrapper}>{child}</div>
        ))}
      </div>
    </CenteredSectionWrapper>
  )
}

FigmaLayout.propTypes = {
  type: PropTypes.oneOf([
    'fiveBySixPieChart',
    'fiveSixVAlignTop',
    'fiveSixVAlignCenter',
    'fourCol',
    'sixFive',
    'sixFiveMobileReversed',
    'textCenter',
    'textLeft',
    'textRight',
    'threeCol',
    'twoCol'
  ]).isRequired
}
