import React from 'react'
import styles from './ragged_edge.module.css'
import greyEdge from './ragged_edge.svg'
import whiteEdge from './ragged_edge_white.svg'

export default function RaggedEdge({ color = null }) {
  let svgFile
  switch (color) {
    case 'white':
      svgFile = whiteEdge
      break
    default:
      svgFile = greyEdge
  }
  return <div className={styles.root} style={{ '--url': `url(${svgFile})` }} />
}
