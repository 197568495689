import React from 'react'
import PropTypes from 'prop-types'
import styles from './stat.module.css'

export default function Stat({ statData }) {
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {statData.stat && <p className={styles.stat}>{statData.stat}</p>}
        {statData.svg && (
          <svg viewBox={statData.svg.viewBox} className={styles.svg}>
            <path d={statData.svg.d} fill={statData.svg.fill} />
          </svg>
        )}

        <p className={styles.statText}>{statData.text}</p>
      </div>
    </div>
  )
}

Stat.propTypes = {
  statData: PropTypes.shape({
    text: PropTypes.string.isRequired,
    stat: PropTypes.string,
    svg: PropTypes.shape({
      viewBox: PropTypes.string,
      d: PropTypes.string,
      fill: PropTypes.string
    }).isRequired
  }).isRequired
}
