import React from 'react'
import { Loading } from '@envato/ui'
import { ErrorBoundary } from 'react-error-boundary'

const Component = React.lazy(() => import('./company_product_section'))

const CompanyProductSection = () => (
  <ErrorBoundary fallback={<Loading takingTooLong />}>
    <React.Suspense fallback={<Loading />}>
      <Component />
    </React.Suspense>
  </ErrorBoundary>
)

export default CompanyProductSection
