import React from 'react'
import PropTypes from 'prop-types'
import styles from './section.module.css'

export default function Section({ background = 'white', children }) {
  return <div className={styles[background]}>{children}</div>
}

Section.propTypes = {
  background: PropTypes.oneOf(['white', 'grey40', 'transparent']),
  children: PropTypes.node.isRequired
}
