import React from 'react'
import Stat from '../stat/stat'
import { earningsStat, customerStat, employeeStat, ownedStat } from '../stat/stat_data'
import styles from './stats.module.css'

export default () => (
  <div className={styles.root}>
    <div className={styles.wrap}>
      <Stat statData={earningsStat} />
      <Stat statData={customerStat} />
      <Stat statData={employeeStat} />
      <Stat statData={ownedStat} />
    </div>
  </div>
)
