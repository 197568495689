export const earningsStat = {
  text: 'in total community earnings',
  stat: '$1+ billion'
}

export const customerStat = {
  text: 'customers every year',
  stat: '2+ million'
}

export const employeeStat = {
  text: 'employees worldwide',
  stat: '600+'
}

export const ownedStat = {
  text: 'founder owned',
  stat: '100%'
}
