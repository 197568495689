import React from 'react'
import styles from './about_header.module.css'
import heroVector from './hero_vector.svg'
import CenteredSectionWrapper from '../centered_section_wrapper/centered_section_wrapper'
import Text from '../text/text'

export default () => (
  <div className={styles.root}>
    <CenteredSectionWrapper>
      <div className={styles.header}>
        <Text block type='preheading'>
          About
        </Text>
        <h1>
          <Text block type='mega'>
            We help people bring their creative ideas to life
          </Text>
        </h1>
      </div>
      <div className={styles.content}>
        <div className={styles.contentText}>
          <h2 className={styles.contentHeader}>
            Envato is a world-leading online community for creative assets and tools
          </h2>
          <p className={styles.contentParagraph}>
            Millions of people around the world visit Envato to buy and sell creative assets, use smart design
            templates, or learn creative skills. With an industry-leading marketplace paired with an unlimited
            subscription service, Envato helps creatives like you get projects done faster.
            <br />
            <br />
            Whether it's graphic templates, website themes, photos, video or audio, there's every chance you’ll spot
            something from Envato today, from a café logo to a Hollywood title sequence!
          </p>
        </div>
        <div className={styles.heroVector}>
          <img src={heroVector} alt='Abstract vector' width='458' height='419' />
        </div>
      </div>
    </CenteredSectionWrapper>
  </div>
)
