import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './text.module.css'

export default function Text({ type, children, block = false }) {
  return <span className={classnames(styles[type], { [styles.block]: block })}>{children}</span>
}

Text.propTypes = {
  type: PropTypes.oneOf([
    'heading',
    'headingGrey',
    'headingTwo',
    'headingThree',
    'mega',
    'preheading',
    'standard',
    'supporting'
  ]).isRequired,
  children: PropTypes.node.isRequired,
  block: PropTypes.bool
}
