import React from 'react'
import PropTypes from 'prop-types'
import styles from './centered_section_wrapper.module.css'

const CenteredSectionWrapper = ({ children, paddingOnSmallDevice = true }) => (
  <div className={paddingOnSmallDevice ? styles.root : styles.rootNoPadSmallMobile}>{children}</div>
)

CenteredSectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  paddingOnSmallDevice: PropTypes.bool
}

export default CenteredSectionWrapper
